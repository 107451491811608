import React from "react";

const GoToTop = () => {
  return (
    <>
      <a href="/" className="btn btn-lg btn-primary btn-lg-square back-to-top">
        <i className="bi bi-arrow-up"></i>
      </a>
    </>
  );
};

export default GoToTop;
