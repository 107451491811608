import React from "react";
import ContactForm from "./ContactForm";

const Contact = () => {
  return (
    <div className="container-xxl pb-5" id="contact">
      <div className="container py-5">
        <div className="row g-5 mb-5 wow fadeInUp" data-wow-delay="0.1s">
          <div className="col-lg-6">
            <h1 className="display-5 mb-0">Let's Work Together</h1>
          </div>
          <div className="col-lg-6 text-lg-end">
            <a className="btn btn-primary py-3 px-5" href="/">
              Say Hello
            </a>
          </div>
        </div>
        <div className="row g-5">
          <div className="col-lg-5 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <hr className="w-100" />
            <p className="mb-2">Call me:</p>
            <h3 className="fw-bold">+919588103862</h3>
            <hr className="w-100" />
            <p className="mb-2">Mail me:</p>
            <h3 className="fw-bold">info@rahulkulchandu.me</h3>
            <hr className="w-100" />
            <p className="mb-2">Follow me:</p>
            <div className="d-flex pt-2">
              <a
                className="btn btn-square btn-primary me-2"
                href="https://www.linkedin.com/in/rahul-saini-7467a620b"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div>
          <div className="col-lg-7 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
