import React from "react";
import ProjectCard from "./ProjectCard";

const Project = () => {
  const imagesData = [
    {
      src: "assets/img/project-1.jpg",
      alt: "",
    },
    {
      src: "assets/img/project-2.jpg",
      alt: "",
    },
    {
      src: "assets/img/project-3.jpg",
      alt: "",
    },
    {
      src: "assets/img/project-4.jpg",
      alt: "",
    },
    {
      src: "assets/img/project-5.jpg",
      alt: "",
    },
    {
      src: "assets/img/project-6.jpg",
      alt: "",
    },
  ];

  return (
    <div className="container-xxl py-6 pt-5" id="project">
      <div className="container">
        <div
          className="row g-5 mb-5 align-items-center wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="col-lg-6">
            <h1 className="display-5 mb-0">My Projects</h1>
          </div>
        </div>
        <div
          className="row g-4 portfolio-container wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <ProjectCard imagesData={imagesData} />
        </div>
        <div className="col-lg-12 text-lg-center mt-4">
          <a className="btn btn-primary py-3 px-5" href="/">
            Load More
          </a>
        </div>
      </div>
    </div>
  );
};

export default Project;
