import React from "react";

const About = () => {
  return (
    <div className="container-xxl py-6" id="about">
      <div className="container">
        <div className="row g-5">
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
            <div className="d-flex align-items-center mb-5">
              <div className="years flex-shrink-0 text-center me-4">
                <h1 className="display-1 mb-0">5</h1>
                <h5 className="mb-0">Years</h5>
              </div>
              <h3 className="lh-base mb-0">
                of working experience as a Laravel Developer, Full Stack
                Developer, Web Developer
              </h3>
            </div>
            <p className="mb-4">
              Passionate Full Stack Developer with expertise in Laravel and web
              technologies. Skilled in creating dynamic, user-friendly
              applications and delivering high-quality solutions to meet diverse
              client needs. Let's build something great together!
            </p>
            <p className="mb-3">
              <i className="far fa-check-circle text-primary me-3"></i>
              Afordable Prices
            </p>
            <p className="mb-3">
              <i className="far fa-check-circle text-primary me-3"></i>High
              Quality Product
            </p>
            <p className="mb-3">
              <i className="far fa-check-circle text-primary me-3"></i>On Time
              Project Delivery
            </p>
            <a className="btn btn-primary py-3 px-5 mt-3" href="/">
              Hire Me
            </a>
          </div>
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
            <div className="row g-3 mb-4">
              <div className="col-sm-6">
                <img
                  className="img-fluid rounded"
                  src="assets/img/about-1.jpg"
                  alt=""
                />
              </div>
              <div className="col-sm-6">
                <img
                  className="img-fluid rounded"
                  src="assets/img/about-2.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="d-flex align-items-center mb-3">
              <h5 className="border-end pe-3 me-3 mb-0">Happy Clients</h5>
              <h2
                className="text-primary fw-bold mb-0"
                data-toggle="counter-up"
              >
                50+
              </h2>
            </div>
            <p className="mb-4">
              Our clients consistently praise our dedication to quality, timely
              delivery, and innovative solutions. We strive to exceed
              expectations and build lasting relationships, ensuring their
              success is our top priority.
            </p>
            <div className="d-flex align-items-center mb-3">
              <h5 className="border-end pe-3 me-3 mb-0">Projects Completed</h5>
              <h2
                className="text-primary fw-bold mb-0"
                data-toggle="counter-up"
              >
                500+
              </h2>
            </div>
            <p className="mb-0">
              We take pride in our diverse portfolio of successful projects.
              Each one showcases our commitment to excellence, creativity, and
              attention to detail, delivering impactful solutions that drive
              results for our clients.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
