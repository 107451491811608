import React from "react";

const ExperienceCard = ({ experienceData }) => {
  return (
    <>
      {experienceData.map((experience, index) => (
        <div className="col-sm-6" key={index}>
          <h5>{experience.designation}</h5>
          <hr className="text-primary my-2" />
          <p className="text-primary mb-1">{experience.duration}</p>
          <h6 className="mb-0">{experience.company}</h6>
        </div>
      ))}
    </>
  );
};

export default ExperienceCard;
