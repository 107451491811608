import React from "react";
import ServicesChild from "./ServicesChild";

const Service = () => {
  const services = [
    {
      title: "Expert Laravel Development",
      description:
        "Build robust and scalable applications using Laravel, ensuring high performance and security tailored to your business needs.",
      price: "$15/hour",
      icon: <i class="fa fa-code fa-2x text-dark"></i>,
    },
    {
      title: "Comprehensive Full Stack Development",
      description:
        "From front-end design to back-end architecture, we provide end-to-end solutions that bring your vision to life seamlessly.",
      price: "$20/hour",
      icon: <i class="fa fa-code fa-2x text-dark"></i>,
    },
    {
      title: "Dynamic React Development",
      description:
        "Create interactive user interfaces with React, delivering a smooth and engaging experience for your users.",
      price: "$16/hour",
      icon: <i className="fa fa-code-branch fa-2x text-dark"></i>,
    },
    {
      title: "Reliable PHP Development",
      description:
        "Develop custom web applications with PHP, leveraging its flexibility and power to meet your specific requirements.",
      price: "$10/hour",
      icon: <i class="fa fa-code fa-2x text-dark"></i>,
    },
    {
      title: "Innovative Mobile Development",
      description:
        "Design and develop mobile applications for iOS and Android, ensuring a user-friendly experience on all devices.",
      price: "$24/hour",
      icon: <i class="fa fa-laptop-code fa-2x text-dark"></i>,
    },

    {
      title: "Seamless API Integration",
      description:
        "Connect your applications effortlessly with our API integration services, enhancing functionality and interoperability.",
      price: "$12/hour",
      icon: <i class="fa fa-code fa-2x text-dark"></i>,
    },
    {
      title: "Secure Payment Gateway Integrations",
      description:
        "Implement reliable payment solutions that ensure secure transactions and improve customer trust.",
      price: "$21/hour",
      icon: <i class="fa fa-code fa-2x text-dark"></i>,
    },
    {
      title: "Stripe Integration Specialists",
      description:
        "Leverage our expertise in Stripe to streamline your payment processing and optimize your eCommerce operations.",
      price: "$20/hour",
      icon: <i class="fa fa-code fa-2x text-dark"></i>,
    },
    {
      title: "Finix Payment Solutions",
      description:
        "Transform your payment processing with our specialized Finix integration services for efficient and flexible transactions.",
      price: "$19/hour",
      icon: <i class="fa fa-code fa-2x text-dark"></i>,
    },
    {
      title: "Square Integration Professionals",
      description:
        "Enhance your business with our expert Square integrations, making payments quick and easy for your customers.",
      price: "$22/hour",
      icon: <i class="fa fa-code fa-2x text-dark"></i>,
    },
    {
      title: "E-commerce App Development",
      description:
        "Launch your online store with our custom e-commerce applications, designed to maximize sales and user experience.",
      price: "$15/hour",
      icon: <i class="fa fa-code fa-2x text-dark"></i>,
    },
    {
      title: "Grocery App Solutions",
      description:
        "Create user-friendly grocery applications that simplify shopping experiences and enhance customer satisfaction.",
      price: "$18/hour",
      icon: <i class="fa fa-code fa-2x text-dark"></i>,
    },
    {
      title: "Transport Services Software Development",
      description:
        "Build efficient transport management systems that streamline operations and improve logistics efficiency.",
      price: "$12/hour",
      icon: <i class="fa fa-code fa-2x text-dark"></i>,
    },
    {
      title: "Logistics Software Solutions",
      description:
        "Develop custom logistics software to optimize your supply chain, enhance visibility, and reduce costs.",
      price: "$20/hour",
      icon: <i class="fa fa-code fa-2x text-dark"></i>,
    },
  ];
  return (
    <div className="container-fluid bg-light my-5 py-6" id="service">
      <div className="container">
        <div className="row g-5 mb-5 wow fadeInUp" data-wow-delay="0.1s">
          <div className="col-lg-6">
            <h1 className="display-5 mb-0">My Services</h1>
          </div>
          <div className="col-lg-6 text-lg-end">
            <a className="btn btn-primary py-3 px-5" href="/">
              Hire Me
            </a>
          </div>
        </div>
        <div className="row g-4">
          <ServicesChild services={services} />
        </div>
      </div>
    </div>
  );
};

export default Service;
