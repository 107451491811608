import React from "react";

const Footer = () => {
  return (
    <div className="container-fluid bg-dark text-white py-4">
      <div className="container">
        <div className="row">
          <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
            &copy; {new Date().getFullYear()} {""}
            <a className="border-bottom text-secondary" href="/">
              rahulkulchandu.me
            </a>{" "}
            All Right Reserved.
          </div>
          <div className="col-md-6 text-center text-md-end"></div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
