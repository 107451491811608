import React from "react";

const ProjectCard = ({ imagesData }) => {
  return (
    <>
      {imagesData.map((image, index) => (
        <div className="col-lg-4 col-md-6 portfolio-item first" key={index}>
          <div className="portfolio-img rounded overflow-hidden">
            <img className="img-fluid" src={image.src} alt={image.alt} />
            <div className="portfolio-btn">
              <a
                className="btn btn-lg-square btn-outline-secondary border-2 mx-1"
                href={image.src}
                data-lightbox="portfolio"
              >
                <i className="fa fa-eye"></i>
              </a>
              <a
                className="btn btn-lg-square btn-outline-secondary border-2 mx-1"
                href="/"
              >
                <i className="fa fa-link"></i>
              </a>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default ProjectCard;
