import React from "react";

const ServicesChild = ({ services }) => {
  return (
    <>
      {services.map((service, index) => (
        <div
          className="col-lg-6 wow fadeInUp"
          data-wow-delay="0.1s"
          key={index}
        >
          <div className="service-item d-flex flex-column flex-sm-row bg-white rounded h-100 p-4 p-lg-5">
            <div className="bg-icon flex-shrink-0 mb-3">{service.icon}</div>
            <div className="ms-sm-4">
              <h4 className="mb-3">{service.title}</h4>
              <h6 className="mb-3">
                Start from <span className="text-primary">{service.price}</span>
              </h6>
              <span>{service.description}</span>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default ServicesChild;
