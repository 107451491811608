import GoToTop from "./components/GoToTop";
import Footer from "./layouts/Footer";
import Header from "./layouts/Header";
import About from "./pages/about/About";
import Contact from "./pages/contact/Contact";
import Home from "./pages/home/Home";
import Project from "./pages/project/Project";
import Service from "./pages/service/Service";
import Skill from "./pages/skill/Skill";
import Team from "./pages/team/Team";
import Testimonial from "./pages/testimonial/Testimonial";

function App() {
  return (
    <>
      <Header />
      <Home />
      <About />
      <Skill />
      <Service />
      <Project />
      {/* <Team /> */}
      <Testimonial />
      <Contact />
      <Footer />
      <GoToTop />
    </>
  );
}

export default App;
